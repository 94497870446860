import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Skills extends Component {
render() {
let resumeData = this.props.resumeData;
return (
<section id="skills">
    
<Container className="skills-container" fluid>
    <Row>
        <Col xs={12} sm={12} md={12}>
            <div className="section-header">
                <h2>{resumeData.skillsHeader}</h2>
            </div>
        </Col>
    </Row>

    <Row>
        <Col xs={12} sm={12} md={12}>
            <div className="skill-icons-wrap">
                <div className="icon-row-1">
                {
                    resumeData.skillsIconGrid1 && resumeData.skillsIconGrid1.map((item)=>{
                        return(
                        
                            <div className="icon-wrap" key={item.iconTitle}><img src={`${item.iconSVG}`} alt={item.iconTitle}/></div>
                        
                        )
                    })
                }
                </div>

                <div className="icon-row-2">
                {
                    resumeData.skillsIconGrid2 && resumeData.skillsIconGrid2.map((item)=>{
                        return(
                        
                            <div className="icon-wrap" key={item.iconTitle}><img src={`${item.iconSVG}`} alt={item.iconTitle}/></div>
                        
                        )
                    })
                }
                </div>

                <div className="icon-row-3">
                {
                    resumeData.skillsIconGrid3 && resumeData.skillsIconGrid3.map((item)=>{
                        return(
                        
                            <div className={`icon-wrap icon-${item.iconTitle}`} key={item.iconTitle}>
                                <img src={`${item.iconSVG}`} alt={item.iconTitle}/>
                                <span className="icon-name">{item.iconTitle}</span>
                            </div>
                        
                        )
                    })
                }
                </div>
                
                
            </div>
        </Col>
    </Row>
</Container>




</section>


);
}
}
