import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Education extends Component {
render() {
let resumeData = this.props.resumeData;
return (
<section id="education">
    
<Container className="education-container">
    <Row>
        <Col xs={12} sm={12} md={12}>
            <div className="section-header">
                <h2>Education</h2>
            </div>
        </Col>
    </Row>

    <Row className="education-row">
        <div className="education-wrap">
            {
                resumeData.education && resumeData.education.map((item)=>{
                    return(
                        <Col xs={12} sm={12} md={12} className="education-column" key={item.UniversityName}>
                        <div className={`school-wrap ${item.UniversityName}`}>
                            <div className="logo-wrap" ><img src={`${item.universityLogo}`} alt={item.UniversityName}/></div>
                            <div className="degree-name">{item.universityDegree}</div>
                            <div className="school-year">{item.YearOfPassing}</div>
                        </div>
                        </Col>
                        
                    
                    )
                })
            }
        </div>
        
    </Row>
</Container>





</section>


);
}
}
