import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'animate.css';

export default class Hero extends Component {
render() {
let resumeData = this.props.resumeData;
return (
<section id="hero">
<Container>
  <Row>
    <Col xs={12} sm={12} md={12} lg={6}>
        <img className="profile-pic animate__animated animate__fast animate__backInLeft" src={resumeData.headShot} alt="headshot" />
    </Col>
    <Col xs={12} sm={12} md={12} lg={6}>
            <div className="hello-world animate__animated animate__fast animate__backInRight animate__delay-1s	1s">
                <h1>{resumeData.heroText}</h1>
            </div>
    </Col>
  </Row>
  
</Container>

<Container className="about-container" fluid>
    <Row>
        <Col xs={12} sm={12} md={12}>
            <div className="about-me">
                    <div dangerouslySetInnerHTML={{ __html: resumeData.aboutme }} />
            </div>
        </Col>
    </Row>
</Container>


</section>
);
}
}