import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Projects extends Component {
render() {
let resumeData = this.props.resumeData;
return (
<section id="projects">
    
<Container className="projects-container">
    <Row>
        <Col xs={12} sm={12} md={12}>
            <div className="section-header">
                <h2>Projects</h2>
                <div className="sub-header">@Mindutopia</div>
            </div>
        </Col>
    </Row>

    <Row className="projects-row">

            {
                resumeData.projectsGrid && resumeData.projectsGrid.map((item)=>{
                    return(
                        <Col xs={12} sm={12} md={6} lg={4} xl={3} className="projects-column" key={item.projectTitle}>
                        <div className={`projects-wrap ${item.projectTitle}`}>
                            <div className="image-wrap" key={item.projectTitle}>
                                <a href={item.projectURL} target="_blank" rel="noreferrer"><img className={`${item.projectSlug}`} src={`${item.projectImage}`} alt={item.projectTitle}/></a>
                            </div>
                            <div className="project-title">
                            <a href={item.projectURL} target="_blank" rel="noreferrer">{item.projectTitle}</a>
                            </div>
                        </div>
                        </Col>
                        
                    
                    )
                })
            }

        
    </Row>
</Container>





</section>


);
}
}
