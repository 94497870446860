import './App.css';

import React, { Component } from 'react';

import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Skills from './components/Skills';
import Experience from './components/Experience';
import Education from './components/Education';
import Projects from './components/Projects';
import Footer from './components/Footer';

import resumeData from './resumeData';
class App extends Component {
render() {
return (
<div className="App">
<Header resumeData={resumeData}/>
<Hero resumeData={resumeData}/>
<About resumeData={resumeData}/>
<Skills resumeData={resumeData}/>
<Experience resumeData={resumeData}/>
<Education resumeData={resumeData}/>
<Projects resumeData={resumeData}/>
<Footer resumeData={resumeData}/>
</div>
);
}
}
export default App;


// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
