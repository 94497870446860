import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Experience extends Component {
render() {
let resumeData = this.props.resumeData;
return (
<section id="experience">
    
<Container className="experience-container" fluid>
    <Row>
        <Col xs={12} sm={12} md={12}>
            <div className="section-header">
                <h2>Experience</h2>
            </div>
        </Col>
    </Row>

   

        <Row className="experience-row">
            {
                resumeData.workExperience && resumeData.workExperience.map((item)=>{
                    return(
                        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="job-column" key={item.workName}>
                        <div className={`job-wrap ${item.workName}`}>
                            <div className="logo-wrap"><img src={`${item.workLogo}`} alt={item.workName}/></div>
                            <div className="work-dates">{item.workDates}</div>
                            <div className="job-title">{item.workTitle}</div>
                            <ul className="job-info-list">

                                {
                                item.jobHighlightsList && item.jobHighlightsList.map((workItem)=>{
                                    return (
                                        <li key={workItem.jobListItem}>
                                            {workItem.jobListItem}
                                        </li>
                                    )
                                })
                                }
                                
                            </ul>
                        </div>
                        </Col>
                        
                    
                    )
                })
            }
            </Row>

        
    
</Container>





</section>


);
}
}
