let resumeData = {
    "headShot": "/images/headshot.jpg",
    "heroText": "< Hello World! />",
    "aboutme":"Hello! My name is Andrew. I’m an experienced <strong>Full Stack Developer & Wordpress Specialist</strong> with over <strong>a decade of experience</strong> building both <strong>Frontend & Backend</strong> web architecture.",
    "overallExperienceIntro": "I’ve worked with all technical aspects of <strong>WordPress</strong>. Primarily using <strong>React, PHP, Javascript, HTML, & CSS,</strong> along with other modern web tools. I’m looking to find a new company that will provide me an opportunity to stay current with the industry trends and leverage new technologies to enhance user experiences.",
    "experienceList": [
        {
            "expList":"Over 9 years of experience with building both frontend & backend of websites.",
        },
        {
            "expList":"Worked with all the technical aspects of WordPress.",
        },
        {
            "expList":"Wrote website code with HTML, CSS, Javascript & PHP.",
        },
        {
            "expList":"Designed and developed website architecture by building custom themes & plugins.",
        },
        {
            "expList":"Implemented new features and functionality on existing websites.",
        },
        {
            "expList":"Support existing client websites by fixing bugs & issues.",
        },
        {
            "expList":"Conducted website performance tests through Google’s PageSpeed Insights & GT Metrix.",
        },
        {
            "expList":"Experience with GIT for version control.",
        },
        {
            "expList":"Maintained reusable & transferable code with well written comments & documentation.",
        },
        {
            "expList":"Experience working in agile work environment.",
        },
    ],
    "skillsHeader":"Skills & Tools",
    "skillsIconGrid1":[
        {
            "iconTitle":"HTML",
            "iconSVG":"/images/icon-html.svg",
        },
        {
            "iconTitle":"CSS",
            "iconSVG":"/images/icon-css.svg",
        },
        {
            "iconTitle":"Javascript",
            "iconSVG":"/images/icon-javascript.svg",
        },
        {
            "iconTitle":"PHP",
            "iconSVG":"/images/icon-php.svg",
        },
        {
            "iconTitle":"SASS",
            "iconSVG":"/images/icon-sass.svg",
        },
        {
            "iconTitle":"jQuery",
            "iconSVG":"/images/icon-jquery.svg",
        },
        

       
    ],
    "skillsIconGrid2":[
        {
            "iconTitle":"mySQL",
            "iconSVG":"/images/icon-mysql.svg",
        },
        {
            "iconTitle":"GIT",
            "iconSVG":"/images/icon-git.svg",
        },
        {
            "iconTitle":"Command Line",
            "iconSVG":"/images/icon-command-line.svg",
        },
        {
            "iconTitle":"WordPress",
            "iconSVG":"/images/icon-wordpress.svg",
        },
        {
            "iconTitle":"responsive-design",
            "iconSVG":"/images/icon-responsive-design.svg",
        },

       
    ],

    "skillsIconGrid3":[
        {
            "iconTitle":"NPM",
            "iconSVG":"/images/icon-npm.svg"
        },
        {
            "iconTitle":"WEBPACK",
            "iconSVG":'data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg fill="%23848484" width="800px" height="800px" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><title>Webpack icon</title><path d="M21.0157 18.1202L12.351 23v-3.8007l5.3986-2.9567 3.266 1.8776zm.5927-.5344V7.3805l-3.1708 1.822v6.5593l3.1708 1.824zm-18.6827.5344L11.5904 23v-3.8007l-5.3986-2.9567-3.266 1.8776zm-.5927-.5344V7.3805l3.1707 1.822v6.5593l-3.1707 1.824zm.371-10.8656l8.8864-5.0056v3.6748L5.8974 8.507l-.0434.0248-3.15-1.8116zm18.5335 0L12.351 1.7146v3.6748l5.693 3.1177.0434.0248 3.15-1.8116zm-9.647 11.6146l-5.3262-2.9155V9.642l5.326 3.062v5.6308zm.7605 0l5.326-2.9155V9.642l-5.326 3.062v5.6308zM6.625 8.9734l5.3467-2.928 5.3468 2.928-5.3468 3.0744L6.625 8.9734z"/></svg>',
        },

       
    ],

    "workExperience":[
        {
            "workName":"Acxiom",
            "workLogo":"/images/logos/logo.png",
            "workDates":"2022 - Present",
            "workTitle":"Web Developer II",
            "jobHighlightsList":[
                {
                    "jobListItem": "Optimized and managed localized websites for the US, UK, Germany, China, and Mexico, enhancing global online presence and performance.",
                },
                {
                    "jobListItem": "Refactored legacy code and integrated modern tools with NPM, Webpack, React, to streamline and modernize development.",
                },
                {
                    "jobListItem": "Developed custom website components with PHP, JavaScript, and React, using modern WordPress block development to enhance user experience and functionality.",
                },
                
            ],

        },
        {
            "workName":"Mindutopia",
            "workLogo":"/images/logos/mindutopia.svg",
            "workDates":"2017 - 2022",
            "workTitle":"Full Stack Web Developer",
            "jobHighlightsList":[
                {
                    "jobListItem": "Built hundreds of client websites using frontend and backend technologies like PHP and JavaScript, delivering robust and dynamic solutions.",
                },
                {
                    "jobListItem": "Worked with UI/UX designers to align WordPress architecture with design goals, improving web experiences and user satisfaction.",
                },
                {
                    "jobListItem": "Collaborated in an agile team, fostering teamwork and iterative development to deliver high-quality web projects.",
                },
                
            ],

        },

        {
            "workName":"Bevelwise",
            "workLogo":"/images/logos/bevelwise.jpeg",
            "workDates":"2016 - 2017",
            "workTitle":"Full Stack Web Developer",
            "jobHighlightsList":[
                {
                    "jobListItem": "Served as a full-stack developer with a focus on PHP and JavaScript, delivering comprehensive frontend and backend solutions.",
                },
                {
                    "jobListItem": "Developed websites optimized for client and company SEO needs, boosting search visibility and online presence.",
                },
                {
                    "jobListItem": "Implemented Git version control for client projects, streamlining collaboration and ensuring code integrity.",
                },                
            ],

        },

        {
            "workName":"Diocesan Publications",
            "workLogo":"/images/logos/diocesan-logo.png",
            "workDates":"2014 - 2016",
            "workTitle":"Web Designer & Developer",
            "jobHighlightsList":[
                {
                    "jobListItem": "Worked with clients to create website wireframes and mockups, aligning designs with their vision and needs.",
                },
                {
                    "jobListItem": "Built frontend and backend systems for websites using PHP and JavaScript, translating client-approved designs into dynamic solutions.",
                },
                {
                    "jobListItem": "Trained clients on WordPress CMS, enabling them to efficiently manage and update their websites.",
                },                
            ],

        },
    ],

    

    "extraSkillsList":[
        {
            "extraSkill":"LAMP",
        },
        {
            "extraSkill":"Bash",
        },
        {
            "extraSkill":"Bootstrap",
        },
        {
            "extraSkill":"WP-CLI",
        },
        {
            "extraSkill":"Agile / Jira",
        },
        {
            "extraSkill":"Bitbucket",
        },
        {
            "extraSkill":"Sourcetree",
        },
        {
            "extraSkill":"Codekit",
        },
        {
            "extraSkill":"WHM / cPanel",
        },
        {
            "extraSkill":"AJAX",
        },
        {
            "extraSkill":"WooCommerce",
        },
        {
            "extraSkill":"Local by Flywheel",
        },
        {
            "extraSkill":"WPEngine",
        },
        {
            "extraSkill":"Gravity Forms",
        },
        {
            "extraSkill":"Beaver Builder",
        },
        {
            "extraSkill":"Adobe XD",
        },
       

       
    ],

    

    "education":[
    {
    "UniversityName":"Grand Valley State University",
    "universityLogo":"/images/logos/gvsu.png",
    "universityDegree":"Bachelors in Business Administration",
    "YearOfPassing":"Class of 2012",

    },

    ],


    "projectsGrid":[
        {
            "projectTitle":"Circon Environmental",
            "projectImage":"/images/project-images/circon-optimized.jpg",
            "projectURL":"https://circonenviro.com/",

        },
        {
            "projectTitle":"Integrated Architecture",
            "projectImage":"/images/project-images/integrated-architecture-optimized.jpg",
            "projectURL":"https://www.intarch.com/",

        },
        {
            "projectSlug": "center-for-cd",
            "projectTitle":"Center for CD",
            "projectImage":"/images/project-images/center-for-cd.png",
            "projectURL":"https://thecenterforcd.com/",

        },
        {
            "projectTitle":"Terra Bagels",
            "projectImage":"/images/project-images/terra-bagels-optimized.jpg",
            "projectURL":"https://terrabagels.com/",

        },
        {
            "projectTitle":"Vinco Prep",
            "projectImage":"/images/project-images/vinco-prep-optimized.jpg",
            "projectURL":"https://vincoprep.com/",

        },
        {
            "projectTitle":"Martin Commercial Properties",
            "projectImage":"/images/project-images/martin-commercial-optimized.jpg",
            "projectURL":"https://martincommercial.com/",

        },
        {
            "projectTitle":"Tech Financial Analysis",
            "projectImage":"/images/project-images/tech-financial-optimized.jpg",
            "projectURL":"https://techfinancialanalysis.com/",

        },
        // {
        //     "projectTitle":"AIAM",
        //     "projectImage":"/images/project-images/aiam-now-optimized.jpg",
        //     "projectURL":"https://aiamnow.com/",
        // },
        {
            "projectTitle":"Cannonsburg Ski Area",
            "projectImage":"/images/project-images/cannonburg-optimized.jpg",
            "projectURL":"https://cannonsburg.com/",

        },
        // {
        //     "projectTitle":"Plastic Surgery Associates",
        //     "projectImage":"/images/project-images/plastic-surgery-optimized.jpg",
        //     "projectURL":"https://www.psa-gr.com/",

        // },
       
        {
            "projectTitle":"Vertigo Music",
            "projectImage":"/images/project-images/vertigo-music-optimized.jpg",
            "projectURL":"https://www.vertigomusic.gr/",

        },
       

       
    ],




    "footerContact":[
        {
        "email":"amrqz21@icloud.com",
        "phoneIcon":"/images/logos/gvsu.png",
        "phoneNumber": "616.510.5803",
        "linkedInIcon": "/images/logos/gvsu.png",
        "linkedInURL": "https://www.linkedin.com/in/andrew-marquez-ab8b6b31/",
        "resumePDFLink":"/Resume_2024.pdf",

    
        },
    
        ],

   
    }
    export default resumeData