import React, { Component } from 'react';
import Logo from '../assets/logo.svg';
import Hamburger from '../assets/hamburger.svg';
import MenuClose from '../assets/icon-close.svg'


import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
//console.log(Logo);
export default class Header extends Component {
render() {
return (
<React.Fragment>
<header id="home">


        <Navbar expand="lg">
            <Container fluid>
                <Navbar.Brand href="#home"><img src={Logo} alt="logo"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="offcanvasNavbar"> <img className="menu-hamburger" src={Hamburger} alt="menu"/> <img className="menu-close" src={MenuClose} alt="menu"/></Navbar.Toggle>

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="justify-content-end">
                            <Nav.Link href="#skills">Skills & Tools</Nav.Link>
                            <Nav.Link href="#experience">Experience</Nav.Link>
                            <Nav.Link href="#projects">Projects</Nav.Link>
                            <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
 
   
</header>
</React.Fragment>
);
}
}