import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
export default class About extends Component {
render() {
let resumeData = this.props.resumeData;
return (
<section id="about">
    
    <Container className="intro-container">

    <Row>
        <Col>
            <div className="intro" dangerouslySetInnerHTML={{ __html: resumeData.overallExperienceIntro }} />

        </Col>
        
    </Row>
    
    </Container>

    <Container className="experience-list-container" fluid>

    {/* <Row>
        <Col>
            <ul className="experience-list">
                {
                resumeData.experienceList && resumeData.experienceList.map((item)=>{
                    return(
                    
                        <li key={item.expList}>{item.expList}</li>
                    
                    )
                })
                }
            </ul>
        </Col>
        
    </Row> */}
    
    </Container>

</section>
);
}
}