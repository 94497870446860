import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';


//import emailjs from '@emailjs/browser';

import EmailIcon from '../assets/icon-email.svg';
import PhoneIcon from '../assets/icon-phone.svg';
import linkedInIcon from '../assets/icon-linkedin.svg';



export default class Footer extends Component {
    render() {
        //const form = this.props.form;

        //const form = Component();
        
      
        // function sendEmail(e)  {
        //   e.preventDefault();
      
        //   emailjs.sendForm('service_5s55qdm', 'template_di08wjf', e.target, 'GaGpIYWFOjLdKmOSX')
        //     .then((result) => {
        //         console.log(result.text);
        //         e.target["user_name"].value = [];
        //         e.target["user_email"].value = [];
        //         e.target["message"].value = [];
        //         alert("Message sent! We'll be in touch shortly!");

        //     }, (error) => {
        //         console.log(error.text);
        //     });
        // };
      
        let resumeData = this.props.resumeData;
        return (
            <footer id="contact">
            <Container className="footer-container" fluid>

                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <div className="section-header">
                            <h2>Lets talk</h2>
                        </div>
                    </Col>
                </Row>

                        {
                            resumeData.footerContact && resumeData.footerContact.map((item)=>{
                                return(
                                    <Row key={item.email}>
                                        <Col xs={12} sm={12} md={12} >
                                            <div className="contact-wrap" >

                                                <div className="email-icon-wrap">
                                                    <a href={`mailto:${item.email}`}>
                                                        <img src={`${EmailIcon}`} alt="email"/>
                                                        <span>{item.email}</span>
                                                    </a>
                                                    
                                                </div>

                                                <div className="phone-icon-wrap">
                                                    <a href={`tel:${item.phoneNumber}`}>
                                                        <img src={`${PhoneIcon}`} alt="phone"/>
                                                        <span>{item.phoneNumber}</span>
                                                    </a>
                                                    
                                                </div>
                                                <div className="linkedin-icon-wrap">
                                                    <a target="_blank" href={item.linkedInURL} rel="noreferrer">
                                                    
                                                        <img src={`${linkedInIcon}`} alt="linkedin"/>
                                                    </a> 
                                                </div>


                                                
                                            </div>
                                        </Col>
                                         <Col  xs={12} sm={12} md={12} key={item.resumePDFLink}>
                                            <div className="download-resume-pdf">
                                                <a target="_blank" href={item.resumePDFLink} rel="noreferrer">Download PDF Resume</a>
                                            </div>
                                        </Col>
                                    </Row>

                                    

                                   
                                )
                            })
                        }
                        

                   
                

               
            </Container>
            </footer>
        );
    }
}